import Ready from '@/utils/ready';

const selector = '[data-ref="slider"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/slider').then((Slider: any) => {
      new Slider.default(element);
    });
  });
})();
