import Ready from '@/utils/ready';

const selector = '[data-ref="burger"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/burger').then((Burger: any) => {
      new Burger.default(element);
    });
  });
})();
