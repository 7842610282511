import Ready from '@/utils/ready';

const selector = '[data-dialog-opener]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    const id = element.dataset.dialogOpener;
    const target = (document as any).querySelector(`[data-dialog-id="${id}"]`);

    element.addEventListener('click', () => {
      const slideTo =
        element.dataset && element.dataset.dialogSlideTo
          ? element.dataset.dialogSlideTo
          : null;
      target.dialog.openDialog(slideTo);
    });
  });
})();
