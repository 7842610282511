import Ready from '@/utils/ready';

const selector = '[data-ref="heroSlider"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/heroSlider').then((Slider: any) => {
      new Slider.default(element);
    });
  });
})();
