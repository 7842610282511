import Ready from '@/utils/ready';

const selector = '[data-ref="typewriter"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/typewriter').then((typewriter: any) => {
      new typewriter.default(element);
    });
  });
})();
