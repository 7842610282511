import Ready from '@/utils/ready';

const selector = '[data-ref="dialog"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/dialog').then((Dialog: any) => {
      const dialog = new Dialog.default(element);
      (element as any).dialog = dialog;
    });
  });
})();
