import Ready from '@/utils/ready';

const selector = '[data-ref="sectionUnderline"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/sectionUnderline').then((SectionUnderline: any) => {
      new SectionUnderline.default(element);
    });
  });
})();
