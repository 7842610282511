import { gsap, Power2, Expo } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

class Scroller {
  private readonly duration: number = 0.75;
  private readonly offset: number = 0;
  constructor(duration: any = 0.75, offset: number = 0) {
    this.duration = duration;
    this.offset = offset;
  }

  public scrollTo(target: string, duration: null, offset: null) {
    let hasTarget = document.querySelector(target);
    let headerSectionBarOffset = (document as any).querySelector('.header')
      .clientHeight;
    if (hasTarget) {
      gsap.to(window, {
        duration: duration || this.duration,
        ease: Expo.easeInOut,
        scrollTo: {
          y: target,
          offsetY: headerSectionBarOffset - (offset || this.offset),
        },
      });
    } else {
      console.log('target does not exist');
    }
  }
}

export default Scroller;
