import Ready from '@/utils/ready';

const selector = '[data-ref="simplebar"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    //@ts-ignore
    import('simplebar').then((SimpleBar: any) => {
      new SimpleBar.default(element, {
        autoHide: false,
      });
    });
  });
})();
