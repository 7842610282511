const screens = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xl2: '1400px',
  xl3: '1600px',
};

const pristineConfig = {
  screens,
  bootstrapBreakpoints: {
    xs: 0,
    ...screens,
  },
  bootstrapMaxWidths: screens,
  gutters: {
    base: '15px',
  },
  colors: {
    primary: '#070903',
    secondary: '#6c8732',
    grey: '#d7d8d8',
  },
  spacing: {
    '4xl': '200px',
    '3xl': '140px',
    '2xl': '70px',
    xl: '45px',
    lg: '30px',
    md: '20px',
    base: '16px',
    sm: '16px',
    xs: '12px',
    '2xs': '6px',
  },
  backgroundColor: {},
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {},
  borderRadius: {
    base: '10px',
  },
  borderWidth: {},
  boxShadow: {},
  container: {},
  cursor: {},
  fill: {},
  flex: {},
  flexGrow: {},
  flexShrink: {},
  fontFamily: {
    mono: ['Prestige'],
    serif: ['Baskerville'],
  },
  fontSize: {
    xl: '35px',
    lg: '25px',
    md: '20px',
    base: '18px',
    sm: '12px',
  },
  fontWeight: {},
  height: {
    '1/2': '50%',
    '2/3': '66.66%',
  },
  inset: {},
  letterSpacing: {},
  lineHeight: {},
  listStyleType: {},
  margin: {},
  maxHeight: {},
  maxWidth: {},
  minHeight: {},
  minWidth: {},
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {},
  stroke: {},
  textColor: {},
  width: {},
  zIndex: {},
};

module.exports = pristineConfig;
