// import Ready from '@/utils/ready';
//
// const selector = '[data-is-legal]';

// Ready.watch(selector, (element: HTMLElement) => {

window.addEventListener('load', () => {
  const dialog: any = document.querySelector('[data-is-legal-dialog]');
  const isLegal: boolean = JSON.parse((localStorage as any).getItem('isLegal'));

  const checkbox: any = dialog.querySelector('[data-checkbox]');
  const submitButton: any = dialog.querySelectorAll('[data-submit]');

  submitButton.forEach((button: any) => {
    const { alertTrans, history }: any = window;
    button.addEventListener('click', (e: any) => {
      if (button.dataset.submit === 'yes') {
        if (checkbox.checked) {
          localStorage.setItem('isLegal', 'true');
        }
      } else {
        alert(alertTrans);
        history.back();
      }
      dialog.closeDialog();
    });
  });

  if (!isLegal) {
    if (!dialog.openDialog) {
      setTimeout(() => {
        dialog.openDialog();
      }, 1000);
    } else {
      dialog.openDialog();
    }
  }
});
// });
